{/* php скрипт */}
/*function nickname_gen() {
    $symbol_arr = array('aeiouy', 'bcdfghjklmnpqrstvwxz');
    $length = mt_rand(5, 8);
    $return = array();
    foreach ($symbol_arr as $k => $v)
        $symbol_arr[$k] = str_split($v);
    for ($i = 0; $i < $length; $i++) {
        while (true) {
            $symbol_x = mt_rand(0, sizeof($symbol_arr) - 1);
            $symbol_y = mt_rand(0, sizeof($symbol_arr[$symbol_x]) - 1);
            if ($i > 0 && in_array($return[$i - 1], $symbol_arr[$symbol_x]))
                continue;
            $return[] = $symbol_arr[$symbol_x][$symbol_y];
            break;
        }
    }
    $return = ucfirst(implode('', $return));
    return $return;
} */


{/* Застрял  на foreach =>. Это из php и на JS слишком запарно реализовывается */}
/*function nickname_gen () {
    function getRandmoInclusive(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    let symbol_arr = ['aeiouy', 'bcdfghjklmnpqrstvwxz'];
    let length = getRandmoInclusive(5, 8);
    let result = [];
    
}*/


export function GetNickname() {
    var rndType = Math.floor(Math.random() * 3) + 1;
    var firstName = "";
    var lastName = "";
    var nickname = "";
    var h_fnpre = new Array("Te", "Ni", "Nila", "Andro", "Androma", "Sha", "Ara", "Ma", "Mana", "La", "Landa", "Do", "Dori", "Pe", "Peri", "Conju", "Co", "Fo", "Fordre", "Da", "Dala", "Ke", "Kele", "Gra", "Grani", "Jo", "Sa", "Mala", "Ga", "Gavi", "Gavinra", "Mo", "Morlu", "Aga", "Agama", "Ba", "Balla", "Ballado", "Za", "Ari", "Ariu", "Au", "Auri", "Bra", "Ka", "Bu", "Buza", "Coi", "Bo", "Mu", "Muni", "Tho", "Thorga", "Ke", "Gri", "Bu", "Buri", "Hu", "Hugi", "Tho", "Thordi", "Ba", "Bandi", "Ga", "Bea", "Beaze", "Mo", "Modi", "Ma", "Malo", "Gholbi", "Gho", "Da", "Dagda", "Nua", "Nuada", "Oghma", "Ce", "Centri", "Cere", "Ce", "Ka", "Kathri", "Ado", "Adora", "Mora", "Mo", "Fe", "Felo", "Ana", "Anara", "Kera", "Mave", "Dela", "Mira", "Theta", "Tygra", "Adrie", "Diana", "Alsa", "Mari", "Shali", "Sira", "Sai", "Saithi", "Mala", "Kiri", "Ana", "Anaya", "Felha", "Drela", "Corda", "Nalme", "Na", "Um", "Ian", "Opi", "Lai", "Ygg", "Mne", "Ishn", "Kula", "Yuni");
    var h_fnsuf = new Array("", "nn", "las", "", "math", "th", "", "ath", "zar", "ril", "ris", "rus", "jurus", "dred", "rdred", "lar", "len", "nis", "rn", "ge", "lak", "nrad", "rad", "lune", "kus", "mand", "gamand", "llador", "dor", "dar", "nadar", "rius", "nius", "zius", "tius", "sius", "wield", "helm", "zan", "tus", "bor", "nin", "rgas", "gas", "lv", "kelv", "gelv", "rim", "sida", "ginn", "grinn", "nn", "huginn", "rdin", "ndis", "bandis", "gar", "zel", "di", "ron", "rne", "lbine", "gda", "ghma", "ntrius", "dwyn", "wyn", "swyn", "thris", "dora", "lore", "nara", "ra", "las", "gra", "riel", "lsa", "rin", "lis", "this", "lace", "ri", "naya", "rana", "lhala", "lanim", "rdana", "lmeena", "meena", "fym", "fyn", "hara");
    var h_lnpre = new Array("Flame", "Arcane", "Light", "Mage", "Spell", "Rex", "Dawn", "Dark", "Red", "Truth", "Might", "True", "Bright", "Pure", "Fearless", "Dire", "Blue", "White", "Black", "Rain", "Doom", "Rune", "Sword", "Force", "Axe", "Stone", "Iron", "Broad", "Stern", "Thunder", "Frost", "Rock", "Doom", "Blud", "Blood", "Stone", "Steel", "Golden", "Gold", "Silver", "White", "Black", "Gravel", "Sharp", "Star", "Night", "Moon", "Chill", "Whisper", "White", "Black", "Saber", "Snow", "Rain", "Dark", "Light", "Wind", "Iron", "Blade", "Shadow", "Flame", "Sin", "Pain", "Hell", "Wrath", "Rage", "Blood", "Terror");
    var h_lnsuf = new Array("seeker", "caster", "binder", "weaver", "singer", "font", "hammer", "redeemer", "bearer", "bringer", "defender", "conjuror", "eye", "staff", "flame", "fire", "shaper", "breaker", "cliff", "worm", "hammer", "brew", "beard", "fire", "forge", "stone", "smith", "fist", "pick", "skin", "smasher", "crusher", "worker", "shaper", "song", "shade", "singer", "ray", "wind", "fang", "dragon", "mane", "scar", "moon", "wood", "raven", "wing", "hunter", "warden", "stalker", "grove", "walker", "master", "blade", "fury", "weaver", "terror", "dweller", "killer", "seeker", "bourne", "bringer", "runner", "brand", "wrath");
    var o_fnpre = new Array("To", "Toja", "Ni", "Niko", "Ka", "Kaji", "Mi", "Mika", "Sa", "Samu", "Aki", "Akino", "Ma", "Mazu", "Yo", "Yozshu", "Da", "Dai", "Ki", "Kiga", "Ara", "Arashi", "Mo", "Moogu", "Ju", "Ga", "Garda", "Ne", "Ka", "Ma", "Ba", "Go", "Kaga", "Na", "Mo", "Kazra", "Kazi", "Fe", "Fenri", "Ma", "Tygo", "Ta", "Du", "Ka", "Ke", "Mu", "Gro", "Me", "Mala", "Tau", "Te", "Tu", "Mau", "Zu", "Zulki", "JoJo", "Sha", "Shaka", "Shakti", "Me", "Mezi", "Mezti", "Vo", "Do", "Du", "Di", "Vu", "Vi", "Dou", "Ga", "Gu", "Fae", "Fau", "Go", "Golti", "Vudo", "Voodoo", "Zolo", "Zulu", "Bra", "Net");
    var o_fnsuf = new Array("jora", "kora", "jind", "kasa", "muro", "nos", "kinos", "zuru", "zshura", "shura", "ra", "sho", "gami", "mi", "shicage", "cage", "gul", "bei", "dal", "gal", "zil", "gis", "le", "rr", "gar", "gor", "grel", "rg", "gore", "zragore", "nris", "sar", "risar", "rn", "gore", "m", "rn", "t", "ll", "k", "lar", "r", "taur", "taxe", "lkis", "labar", "bar", "jas", "lrajas", "lmaran", "ran", "kazahn", "zahn", "hn", "lar", "tilar", "ktilar", "zilkree", "kree", "lkree", "jin", "jinn", "shakar", "jar", "ramar", "kus", "sida", "Worm");
    if (rndType == 1) {
        var fnprefix1 = Math.floor(Math.random() * 122);
        var fnsuffix1 = Math.floor(Math.random() * 91);
        var lnprefix1 = Math.floor(Math.random() * 67);
        var lnsuffix1 = Math.floor(Math.random() * 64);
        firstName = h_fnpre[fnprefix1] + h_fnsuf[fnsuffix1];
        lastName = h_lnpre[lnprefix1] + h_lnsuf[lnsuffix1];
        // firstName1 = firstName.substr(0, 1).toUpperCase();
        //firstName = firstName1 + firstName.substr(1, firstName.length);
        // lastName1 = lastName.substr(0, 1).toUpperCase();
        //lastName = lastName1 + lastName.substr(1, lastName.length);
        var rnd = Math.floor(Math.random() * 2) + 1;
        if (rnd == 1) nickname = firstName;
        else nickname = lastName;
    } else {
        var fnprefix1 = Math.floor(Math.random() * 122);
        var fnsuffix1 = Math.floor(Math.random() * 91);
        firstName = h_fnpre[fnprefix1] + h_fnsuf[fnsuffix1];
        nickname = firstName;
    }
    return nickname;
}

// function GenNickname(input) {
//     document.getElementById(input).value = GetNickname();
// }