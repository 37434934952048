import React from 'react';
import PropTypes from 'prop-types';
import { Card, Title, Link, Caption } from '@vkontakte/vkui';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import CardGrid from '@vkontakte/vkui/dist/components/CardGrid/CardGrid';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import './Tools.css';

const Tools = props => (
	<Panel id={props.id}>
		<PanelHeader>
			Инструменты
		</PanelHeader>
      <Group separator="hide"> {/*{header={<Header mode="secondary">Дефолтный стиль</Header>}*/ }
        <CardGrid>
			{props.buttonsTools}
        </CardGrid> 
		<div style={{textAlign: 'center', marginTop:20}}>
						<Link onClick={props.shareApp}>Поделиться приложением</Link>
		</div>
		<Caption className='caption' style={{textAlign: 'center', marginTop:10}}>Версия приложения: 1.4.6</Caption>
		{props.snackbar}
      </Group>
	</Panel>
);

Tools.propTypes = {
	id: PropTypes.string.isRequired,
};

export default Tools;
