import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Caption from '@vkontakte/vkui/dist/components/Typography/Caption/Caption';
import Title from '@vkontakte/vkui/dist/components/Typography/Title/Title';
import Text from '@vkontakte/vkui/dist/components/Typography/Text/Text';

import './HelpGuide.css';
import './HelpGuideAnimation.js'

const HelpGuide = props => (
	<Panel id={props.id} >
        <Div id='first' className='mainDiv'>
            <Title className='title_guide' level="1" weight="bold">Добро пожаловать в <span id='dynamicName' className='title_guide' level="1" >TextTools!</span></Title>   
            <Text className='description elem_for_show'>Используй возможности спец-символов на максимум! Украшай текст, пиши невидимые сообщения, рисуй и многое другое!</Text>
            <div className='buttons visuallyhidden hidden elem_for_show'>
                <Button size="xl" mode="overlay_outline" style={{marginTop: 40}} onClick={() => props.setGuideStep('2')}>Круто! Хочу узнать подробнее</Button>
                <Caption level="1" weight="semibold" className='skip_guide' onClick={() => props.closeGuide(1)}>пропустить обучение</Caption>
            </div>
        </Div>
	</Panel>
);

HelpGuide.propTypes = {
	id: PropTypes.string.isRequired,
	fetchedUser: PropTypes.shape({
		id: PropTypes.string,
		first_name: PropTypes.string,
		last_name: PropTypes.string,
	}),
};

export default HelpGuide;