import React from 'react';
import PropTypes from 'prop-types';
import { platform, IOS, Div, List, Cell } from '@vkontakte/vkui';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import PanelHeaderButton from '@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton';

import Header from '@vkontakte/vkui/dist/components/Header/Header';
import Icon24DismissSubstract from '@vkontakte/icons/dist/24/dismiss_substract';
import CardGrid from '@vkontakte/vkui/dist/components/CardGrid/CardGrid';
import SelectMimicry from '@vkontakte/vkui/dist/components/SelectMimicry/SelectMimicry';
import Card from '@vkontakte/vkui/dist/components/Card/Card';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import Headline from '@vkontakte/vkui/dist/components/Typography/Headline/Headline';
import Title from '@vkontakte/vkui/dist/components/Typography/Title/Title';
import Caption from '@vkontakte/vkui/dist/components/Typography/Caption/Caption';
import Icon28AddCircleOutline from '@vkontakte/icons/dist/28/add_circle_outline';

import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';

import './ShowScreenSym.css'

const osName = platform();

const SymCatalog = props => (
	<Panel id={props.id}>
		<PanelHeader left={<PanelHeaderButton onClick={() => props.goHistoryBack()}>
				{osName === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
			</PanelHeaderButton>}>
            {props.state.name}
        </PanelHeader>
		<Div className={props.state.style}>
			{props.state.content}
		</Div>
        {props.snackbarShowSym}
	</Panel>
);

SymCatalog.propTypes = {
	id: PropTypes.string.isRequired,
};

export default SymCatalog;