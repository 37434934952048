import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import CardGrid from '@vkontakte/vkui/dist/components/CardGrid/CardGrid';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Caption from '@vkontakte/vkui/dist/components/Typography/Caption/Caption';
import { Input, SelectMimicry, FormLayout, Card, Separator, Link, Touch, Textarea } from '@vkontakte/vkui';

import './Home.css';

const Home = props => (
	<Panel id={props.id} >
		{/* <Div className="header"></Div>sdsdsdd */}
		{/* 		<Div className="box">
		
		</Div> */}
		<PanelHeader >Конвертер</PanelHeader>
			<Div>
				<FormLayout>
					
					<Textarea  top={<span >{props.textForConvert.length}/100</span>} type="text" placeholder="Введите текст для оформления" onChange={props.convertText} value={props.textForConvert}/>
					
					<SelectMimicry
							top="Выберите стиль оформления"
							placeholder="Не выбран"
							onClick={() => props.goToPage({name: 'select_styles', func: props.setConverCurrentWindow}) }>
					  {props.styleName}
					</SelectMimicry>
					{/* <Caption className='caption'>Большинство из шрифтов адаптированны только под латинские символы ^_^</Caption> */}
					<div style={{textAlign: 'center'}}>
						<Link onClick={() => props.goToPage({name: 'error_text', func: props.setConverCurrentWindow})}>Не отображаются символы?</Link>
					</div>
					
					{/* <Separator/> */}

					<CardGrid>
						{props.convertResult}	
					</CardGrid>

				</FormLayout>
				
				

			</Div>
			{props.snackbar}
	</Panel>
);

Home.propTypes = {
	id: PropTypes.string.isRequired,
	fetchedUser: PropTypes.shape({
		id: PropTypes.string,
		first_name: PropTypes.string,
		last_name: PropTypes.string,
	}),
};

export default Home;
