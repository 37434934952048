import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Caption from '@vkontakte/vkui/dist/components/Typography/Caption/Caption';
import Title from '@vkontakte/vkui/dist/components/Typography/Title/Title';
import Text from '@vkontakte/vkui/dist/components/Typography/Text/Text';

import './HelpGuide.css';
import './HelpGuideAnimation.js'
import { CardGrid, Card } from '@vkontakte/vkui';

import emoji from '../img/smile-sym.png';
const HelpGuide = props => (
	<Panel id={props.id} >
        <Div id='third' className='mainDiv'>
            <Title className='title_guide' level="1" weight="bold" style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>Твори символами</Title>   
            <CardGrid style={{marginTop: 20}}>
                <Card size="l" mode="shadow"className='card_guide'>
                        <Div className='card_guide_content'  style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black', width: 300, height: 200}}>
                        <img src={emoji}></img>
                        </Div>
                </Card>
            </CardGrid>
            <Text className='description_3'>Рисуй картинки, украшай текст и удивляй с помощью символов в нашем специальном каталоге! </Text>
            <div className='buttons_3' style={{display: 'flex', marginTop: 40}}>
                {/* <Button size="xl" mode="overlay_outline" style={{marginRight: 20}} onClick={() => props.setGuideStep('1')}>Назад</Button> */}
                <Button id='skip_guide' size="xl" mode="overlay_outline" onClick={props.closeGuide}>В приложение!</Button>
                {/* <Caption level="1" weight="semibold" className='skip_guide' onClick={props.closeGuide}>пропустить обучение</Caption> */}
            </div>
        </Div>
	</Panel>
);

HelpGuide.propTypes = {
	id: PropTypes.string.isRequired,
	fetchedUser: PropTypes.shape({
		id: PropTypes.string,
		first_name: PropTypes.string,
		last_name: PropTypes.string,
	}),
};

export default HelpGuide;
	{/* <Div className='mainDiv'>
                <Title className='title_guide' level="1" weight="bold">Делай красиво  <img src={RoundStar} width={35} height={35} style={{paddingInline: 5}}/></Title> 
                <Text>aifajasdajdlkjasjd</Text>
			</Div> */}