import React from 'react';
import PropTypes from 'prop-types';
import { Cell, Root, Card, Div, Snackbar, Avatar, Spinner, platform, CardScroll, IOS} from '@vkontakte/vkui';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import PanelHeaderButton from '@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import Header from '@vkontakte/vkui/dist/components/Header/Header';
import CardGrid from '@vkontakte/vkui/dist/components/CardGrid/CardGrid';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import Headline from '@vkontakte/vkui/dist/components/Typography/Headline/Headline';
import Title from '@vkontakte/vkui/dist/components/Typography/Title/Title';
import Caption from '@vkontakte/vkui/dist/components/Typography/Caption/Caption';



// import Icon56TextLiveOutline from '@vkontakte/icons/dist/56/text_live_outline';
import Icon24MoreHorizontal from '@vkontakte/icons/dist/24/more_horizontal';
import Icon24Done from '@vkontakte/icons/dist/24/done';
// import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
// import Icon24Back from '@vkontakte/icons/dist/24/back';

import './FontCatalog.css';

import RoundStar from '../img/round_star.png'
import Hammer from '../img/hammer.png';
import BritishFlag from '../img/britain_flag.png';


const MoreStyles = props => (
	<Panel id={props.id}>
        {/* <PanelHeader
			left={<PanelHeaderButton onClick={() => props.setConverCurrentWindow('select_styles')}>
				{platform() === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
			</PanelHeaderButton>}
		>
			dddd
		</PanelHeader> */}
		{props.content}
    </Panel>
);

MoreStyles.propTypes = {
	id: PropTypes.string.isRequired,
};

export default MoreStyles;