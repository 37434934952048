import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import CardGrid from '@vkontakte/vkui/dist/components/CardGrid/CardGrid';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Caption from '@vkontakte/vkui/dist/components/Typography/Caption/Caption';
import Title from '@vkontakte/vkui/dist/components/Typography/Title/Title';
import Text from '@vkontakte/vkui/dist/components/Typography/Text/Text';


import { Input, SelectMimicry, FormLayout, Card, Separator, Link, List} from '@vkontakte/vkui';

import Icon28CancelOutline from '@vkontakte/icons/dist/28/cancel_outline';

import './ErrorText.css';

const ErrorText = props => (
	<Panel id={props.id} >
		<PanelHeader left={<Icon28CancelOutline style = {{align: 'center'}} className = 'cancel-icon' onClick={() => props.goHistoryBack()}/>}>Проблемы</PanelHeader>
			<Div>
                {/* <Text  weight="regular" className='text'>К сожалению, мы не можем гарантировать, что все символы будут корректно отображаться на всех устройствах и платформах :(</Text> */}
                <Title level="1" weight="bold" className='title'>Как исправить проблему с отображением символов?</Title>
                {/* <Caption className='caption_error'>На компьютерах таких проблем нет, поэтому советы предназначены для мобильных пользователей.</Caption> */}
                <List>
                    <Text weight="medium" className='list_item'>используйте последнюю версию вашей ОС (Android или iOS).</Text>
                    <Text weight="medium" className='list_item'>используйте последнюю версию официального мобильного <Link href='https://vk.com/android_app' target="_blank">приложения ВКонтакте.</Link></Text>
                    <Text weight="medium" className='list_item'>попробуйте открыть сервис на другом мобильном устройстве.</Text>
                </List>
                <Text weight="medium" className='text' style={{paddingTop: 10}}>Если приведенные выше советы не помогли, то вам остается только ждать обновления ОС для вашего устройства и не использовать некоторые стили в нашем приложении.</Text>
                 <Text weight="medium" className='text' style={{paddingTop: 10}}>Мы никак не можем на это повлиять <span className='no-wrap'>¯\_(ツ)_/¯</span>.</Text>
			</Div>
			{props.snackbar}
	</Panel>
);

ErrorText.propTypes = {
	id: PropTypes.string.isRequired,
	fetchedUser: PropTypes.shape({
		id: PropTypes.string,
		first_name: PropTypes.string,
		last_name: PropTypes.string,
	}),
};

export default ErrorText;
