import React from 'react';
import PropTypes from 'prop-types';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Caption from '@vkontakte/vkui/dist/components/Typography/Caption/Caption';
import Title from '@vkontakte/vkui/dist/components/Typography/Title/Title';
import Text from '@vkontakte/vkui/dist/components/Typography/Text/Text';

import './HelpGuide.css';

import { CardGrid, Card } from '@vkontakte/vkui';
const HelpGuide = props => (
	<Panel id={props.id} >
        <Div id='second' className='mainDiv'>
            <Title className='title_guide' level="1" weight="bold" style={{display: 'inline-flex', justifyContent: 'left', alignItems: 'center'}}>Оформляй символами</Title>  
            <CardGrid style={{marginTop: 20}}>
                <Card size="l" mode="shadow" className='card_guide'>
                        <Div className='result_card card_guide_content'>
                        『H』『e』『l』『l』『o』『!』
                        </Div>
                </Card>
                <Card size="l" mode="shadow" className='card_guide'>
                    <Div className='result_card card_guide_content'>
                    ≋H≋e≋l≋l≋o≋!≋
                    </Div>
                </Card>
                <Card size="l" mode="shadow" className='card_guide'>
                    <Div className='result_card card_guide_content'>
                    ℍ𝕖𝕝𝕝𝕠!
                    </Div>
                </Card>
            </CardGrid> 
            <Text className='description_2'>Оформи комментарий, пост или сообщение с помощью наших шрифтов или придумай свой уникальный стиль! В твоем распоряжении 50+ шрифтов и сотни символов!</Text>
            <div className='buttons_2' style={{display: 'flex', marginTop: 20}}>
                {/* <Button size="xl" mode="overlay_outline" style={{marginRight: 20}} onClick={() => props.setGuideStep('1')}>Назад</Button> */}
                <Button size="xl" mode="overlay_outline" onClick={() => props.setGuideStep('3')}>Далее</Button>
                {/* <Caption level="1" weight="semibold" className='skip_guide' onClick={props.closeGuide}>пропустить обучение</Caption> */}
            </div>
        </Div>
	</Panel>
);

HelpGuide.propTypes = {
	id: PropTypes.string.isRequired,
	fetchedUser: PropTypes.shape({
		id: PropTypes.string,
		first_name: PropTypes.string,
		last_name: PropTypes.string,
	}),
};

export default HelpGuide;
	{/* <Div className='mainDiv'>
                <Title className='title_guide' level="1" weight="bold">Делай красиво  <img src={RoundStar} width={35} height={35} style={{paddingInline: 5}}/></Title> 
                <Text>aifajasdajdlkjasjd</Text>
			</Div> */}