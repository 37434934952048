import React from 'react';
import PropTypes from 'prop-types';
import {Div, FormLayout, Textarea, platform, IOS, PanelHeaderButton  } from '@vkontakte/vkui';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import CardGrid from '@vkontakte/vkui/dist/components/CardGrid/CardGrid';

import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';

const osName = platform();


const FlexFlip = props => (
	<Panel id={props.id}>
       <PanelHeader left={<PanelHeaderButton onClick={() => props.goHistoryBack()}>
				                                    {osName === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
			                                            </PanelHeaderButton>}>Переворот</PanelHeader>
       <Div>
                    					{/* <Separator/> */}
                    <FormLayout>
					<Textarea  type="text" placeholder="Введите текст" top={<span >{props.textForFlip.length}/200</span>} onChange={props.TextFlip} value={props.textForFlip}/>
                    </FormLayout>
					<CardGrid>
                        {props.convertFlipResult} 
						{/* {props.convertResult}	 */}
					</CardGrid>
                    {/* <Div style={{paddingTop: 15}}><Caption className='caption' >Ты можешь придать нику уникальный стиль в нашем конвертере текста!</Caption></Div> */}

				
				
        {props.snackbarFlip}
		</Div>
    </Panel>
    );

FlexFlip.propTypes = {
	id: PropTypes.string.isRequired,
};

export default FlexFlip;