import React from 'react';
import PropTypes from 'prop-types';
import {List} from '@vkontakte/vkui';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';



const SymCatalog = props => (
	<Panel id={props.id}>
		<PanelHeader>
            Спецсимволы
        </PanelHeader>
		<List>
			{props.content}
		</List>
	</Panel>
);

SymCatalog.propTypes = {
	id: PropTypes.string.isRequired,
};

export default SymCatalog;