import "core-js/features/map";
import "core-js/features/set";
import React from "react";
import ReactDOM from "react-dom";
import bridge from "@vkontakte/vk-bridge";
import App from "./App";

bridge.subscribe(({ detail: { type, data }}) => {
	if (type === "VKWebAppUpdateConfig") {
		console.log(data);
		const schemeAttribute = document.createAttribute("scheme");
		schemeAttribute.value = data.scheme ? data.scheme : "bright_light";
		// sheme = data.scheme ? data.scheme : "bright_light";
		console.log(data.scheme)
		if(data.appearance === 'light'){
			bridge.send("VKWebAppSetViewSettings", {"status_bar_style": "dark", action_bar_color: '#fff'})
		}
		else{
			bridge.send("VKWebAppSetViewSettings", {"status_bar_style": "light", action_bar_color: '#19191a'})
		}
		document.body.attributes.setNamedItem(schemeAttribute);
	}
	// if (type === "VKWebAppUpdateConfig") {
	// 	const isLight = ['bright_light', 'client_light'].includes(data.scheme);
	// 	const shemeName = isLight ? 'bright_light' : 'space_gray';

	// 	bridge.send("VKWebAppSetViewSettings", {
	// 		status_bar_style: isLight ? "light" : "dark",
	// 		action_bar_color: isLight ? '#fff' : '#333333'
	// 	});
	// 	document.body.setAttribute('scheme', shemeName);
	// }
});

bridge.send("VKWebAppInit");

ReactDOM.render(<App/>, document.getElementById("root"));
if (process.env.NODE_ENV === "development") {
  import("./eruda").then(({ default: eruda }) => {}); //runtime download
}
