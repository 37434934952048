import React, { useState, useEffect } from 'react';
import { Tabbar, ScreenSpinner, TabbarItem, PanelHeader, Group, Root, Card, Div, Snackbar, Avatar, Spinner, platform, Tooltip, CardScroll, Header, PanelHeaderButton, IOS, ANDROID, CardGrid, Cell} from '@vkontakte/vkui';
import bridge from '@vkontakte/vk-bridge';
import View from '@vkontakte/vkui/dist/components/View/View';
import Epic from '@vkontakte/vkui/dist/components/Epic/Epic';
import ConfigProvider from '@vkontakte/vkui/dist/components/ConfigProvider/ConfigProvider';

import copy from "copy-to-clipboard";  
import Caption from '@vkontakte/vkui/dist/components/Typography/Caption/Caption';
import Title from '@vkontakte/vkui/dist/components/Typography/Title/Title';

import '@vkontakte/vkui/dist/vkui.css';

import Icon28SubtitlesOutline from '@vkontakte/icons/dist/28/subtitles_outline';
import Icon28ServicesOutline from '@vkontakte/icons/dist/28/services_outline';
import Icon28GhostOutline from '@vkontakte/icons/dist/28/ghost_outline';
import Icon16Done from '@vkontakte/icons/dist/16/done';
import Icon16Cancel from '@vkontakte/icons/dist/16/cancel';

import Icon28GlobeOutline from '@vkontakte/icons/dist/28/globe_outline';
import Icon24MoreHorizontal from '@vkontakte/icons/dist/24/more_horizontal';

import Icon28MentionOutline from '@vkontakte/icons/dist/28/mention_outline';
import Icon28MagicWandOutline from '@vkontakte/icons/dist/28/magic_wand_outline';

import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';

import {generate, fonts, applyCharMap} from './text-convert/generate';
import {GetNickname} from './text-convert/nickGenerate';
import {symbols} from  './text-sym/symbols';

import Home from './panels/Home';
import Tools from './panels/Tools';
import FontCatalog from './panels/FontCatalog';
import MoreStyles from './panels/MoreStyles';
import ErrorText from './panels/ErrorText';

import BritishFlag from './img/britain_flag.png';

import GenerateRandomNick from './panels/GenerateRandomNick';

import DrawComponent from './draw/DrawComponent'

import HelpGuide_1 from './panels/Guides/1/HelpGuide';
import HelpGuide_2 from './panels/Guides/2/HelpGuide';
import HelpGuide_3 from './panels/Guides/3/HelpGuide';

import SymCatalog from './panels/SymCatalog';
import ShowScreenSym from './panels/ShowScreenSym';

import {flipString} from './text-convert/flip';
import {translit} from './text-convert/translit';
import FlexFlip from './panels/FlexFlip';
import TranslitPanel from './panels/Translit';

import Icon24BrowserForward from '@vkontakte/icons/dist/24/browser_forward';

import * as scroll_utils from './panels/Guides/utils/scroll_locker';

const isOnline = require('is-online');
var Scroll = require('react-scroll');
var scroll = Scroll.animateScroll;
 
let screen_names = ['convert', 'sym_catalog', 'tools']
let nick_list = [];

let withoutEmoji = require('emoji-aware').withoutEmoji;
const tool_buttons = [
		// {id: 'picture-sym', panel_id: 'picture-sym', text: 'Рисунки из спец-символов', icon: <Icon28GhostOutline className = 'CardIcon' width={56} height={56} style = {{ color: 'var(--counter_primary_text)'}}/>},
		// {id: 'fonts-library', panel_id: 'fonts-library', text: 'Каталог спец-символов', icon: <Icon28CubeBoxOutline className = 'CardIcon' width={56} height={56} style = {{ color: 'var(--counter_primary_text)'}}/>},
		{id: 'nick-generate', panel_id: 'nick-generate', text: 'Генератор ников', icon: <Icon28MagicWandOutline className = 'CardIcon' width={56} height={56} style = {{ color: 'var(--counter_primary_text)'}}/>},
		// {id: 'story_editor', panel_id: 'story_editor', text: 'Редактор историй', icon: <Icon28LogoInstagram className = 'CardIcon' width={56} height={56} style = {{ color: 'var(--counter_primary_text)'}}/>},
		{id: 'FlexFlip', panel_id: 'FlexFlip', text: 'Переворот текста', icon: <Icon28MentionOutline className = 'CardIcon' width={56} height={56} style = {{ color: 'var(--counter_primary_text)'}}/>},
		{id: 'Translit', panel_id: 'Translit', text: 'Транслитерация', icon: <Icon28GlobeOutline className = 'CardIcon' width={56} height={56} style = {{ color: 'var(--counter_primary_text)'}}/>},
];

const OS_name = platform();

let URL_PARAMS = window.location.search.slice(1);
if(URL_PARAMS.length < 10){
	URL_PARAMS = 'vk_access_token_settings=&vk_app_id=7557452&vk_are_notifications_enabled=0&vk_is_app_user=0&vk_is_favorite=0&vk_language=ru&vk_platform=mobile_web&vk_ref=other&vk_user_id=242306128&sign=LBUhYdF7zA_pb_W4V8e3dF0tUVoGw7uFrENH1ybrYpI';
}
const qs = require('querystring');
const urlParams = qs.parse(URL_PARAMS);
console.log(urlParams.vk_platform)
// let isNewUserApp = urlParams.vk_is_app_user === '0';
// let isNewUserApp = bridge.send("VKWebAppStorageGet", {"keys": ["is_new"]})
// 								.then(res => { return res['keys']['is_new']}); 
// console.log(isNewUserApp);
let symetricSym = 'онжхиxslz80%o+=:-'

let countCopy = 0;
const showAddCopy = 2;

const goodSym = '&@\'^#1234567890-=+_ё~`йцукенгшщзхъфывапролджэ\\/\\ячсмитьбю,.!"№;%:?$*()<>qwertyuiop[]{}asdfghjkl|zxcvbnm ';
let styleName = 'Случайные стили';
let lastObject = {};
let currentTab = 'convert';
let isSwipe = true;
let lastGoBack = {};
let shemeLast = null;
let shemeCurrent = null;
// let isTransition = true;
let textForConvert = '';
let isIphone5 = window.screen.height < 666 & window.screen.width < 375 & OS_name === IOS;
let isMobileWeb = urlParams.vk_platform === 'mobile_web';
let textForFlip = '';
let textForTranslit = ''
let addIsLoadNow = false;
let stateConvert = {
	id: 0,
	style_name: 'Случайные стили',
	style_type: undefined,
	style_function: undefined,
	RandomStyles_default: true,
}

function removeInvalidChars(s) {
	return withoutEmoji(s).join('');
   }

function isBadSymIn(s) {
	for(let char of s.replace(/\r?\n/g, "")){
		if(!goodSym.includes(char.toLowerCase())){
			return true;
		}
	}
	return false;
}
// bridge.send("VKWebAppStorageSet", {"key": "is_new", "value": ""});
const App = props => {

	const [snackbar, setSnackbar] = useState(null);
	const [snackbarNick, setSnackbarNick] = useState(null);
	const [snackbarShowSym, setSnackbarShowSym] = useState(null);
	const [snackbarFlip, setSnackbarFlip] = useState(null);
	// загрузка карточек
	const [convertResult, setConvertResult] = useState(<Spinner size="large" style={{ marginTop: 20 }} />);
	const [convertFlipResult, setConvertFlipResult] = useState(<Card key={makeid(5)} size="l" mode="shadow" >
	<Div className='result_card' style={{textAlign: 'center'}}>
		<Caption className='caption'>Введите текст, и он перевернется <span className='no-wrap'>(✯◡✯)</span></Caption>
	</Div>
	</Card>);
	const [convertTranslitResult, setConvertTranslitResult] = useState(<Card key={makeid(5)} size="l" mode="shadow" >
	<Div className='result_card' style={{textAlign: 'center'}}>
		<Caption className='caption'>Введите текст, и произойдет транслитерация <span className='no-wrap'>ʕ•ᴥ•ʔ</span></Caption>
	</Div>
	</Card>);
	const [stateSymShowScreen, setStateSymShowScreen] = useState({
		
	});
	// управление экранами
	const [activePanel, setActivePanel] = useState('convert');
	const [rootPanel, setRootPanel] = useState('main');
	// const [rootPanel, setRootPanel] = useState('main');
	const [activePanelSym, setActivePanelSym] = useState('sym_catalog');
	const [guideStep, setGuideStep] = useState('1');
	// разное
	const [nickCards, setNickCards] = useState(null);
	const [popoutTools, setPopoutTools] = useState(null);

	const [keyMoreStyles, setKeyMoreStyles] = useState(null);
	const [converCurrentWindow, setConverCurrentWindow] =  useState('convert')
	// const [textForConvert, setTextForConvert] = useState('');
	const [isTransition, setIsTransition] = useState(true);


	// const [stateConvert, setStateConvert] = useState({
	// 	id: 0,
	// 	style_name: 'Случайные стили',
	// 	style_type: undefined,
	// 	style_function: undefined,
	// 	RandomStyles_default: true,
	// 	currentWindow: 'convert'
	// }); 

	const [activePanelTools, setActivePanelTools] = useState('tools');

	let [stateToolTip, setStateToolTip] = useState({
		tooltip_convert: false,
		tooltip_select_style: false,
	});

	const [history, setHistory] = useState({
		convert: [{name: 'convert', func: setConverCurrentWindow}],
		sym_catalog: [{name: 'sym_catalog', func: setActivePanelSym}],
		tools: [{name: 'tools', func: openRootTools}]
	})
	useEffect(() => {
		GenerateNick();

		bridge.subscribe(({ detail: { type, data }}) => {
			if (type === "VKWebAppUpdateConfig") {
				shemeCurrent = data.scheme;
				setPopoutTools(null);
				setSnackbar(null);
				setSnackbarNick(null);
				setSnackbarFlip(null);
				setSnackbarShowSym(null);
			}
		});
		// setRootPanel('guide');
		bridge.send("VKWebAppStorageGet", {"keys": ["is_new"]})
				.then(res => {

					setStateToolTip({
						tooltip_convert: res['keys'][0].value === '',
						tooltip_select_style: res['keys'][0].value === '',
					})
					stateToolTip = {
						tooltip_convert: res['keys'][0].value === '',
						tooltip_select_style: res['keys'][0].value === '',
					};
					
					setRootPanel(res['keys'][0].value === '' ? 'guide' : 'main')
					if(res['keys'][0].value === ''){
						scroll_utils.default.enable();
						// scroll_utils.default.play();
						bridge.send("VKWebAppStorageSet", {"key": "is_new", "value": "already_app"});
					}
					setFontCards(generateCardScroll_SelectStyle());
					if(textForConvert === ''){
						convertText({target: {
						value: 'Hello!',
						isFirstChange: true
						}});
					}
					
		bridge.send("VKWebAppInit")});
		window.addEventListener('popstate', e => {goBack()});		  
	}, []);

	function goBack() {
		if(OS_name === IOS & isSwipe){
			setIsTransition(false);
		}
		let current_history = history[currentTab];
		if( current_history.length === 1 ) {  // Если в массиве одно значение:
		  bridge.send("VKWebAppClose", {"status": "success"}); // Отправляем bridge на закрытие сервиса.
		  console.log('service close')
		} else if( current_history.length > 1 ) { // Если в массиве больше одного значения:
			current_history.pop() // удаляем последний элемент в массиве.
			let currentEventHistory = current_history[current_history.length - 1];
			let {func, name} = currentEventHistory;
			if(screen_names.includes(name)){
				bridge.send('VKWebAppSetSwipeSettings', {"history": false});
			}
			if(func === '1'){
				setActivePanel(name);
				}
			else {
				lastGoBack = currentEventHistory;
				func(name);	
				if(isSwipe){
					setTimeout(() => {
						if(func === lastGoBack['func'] && name === lastGoBack['name']){
							if(screen_names.includes(name)){
								console.log(currentTab, name);
								if(currentTab === name){
									let new_names = screen_names.slice(0);
									new_names.splice(new_names.indexOf(name));
									setActivePanel(new_names[0]);
									setActivePanel(name);
								}
								
								// window.scrollTo(0,0);
							}
							else{
								func(name);
							}	
						}
					},	OS_name === IOS && isSwipe ? 160: 350 
						)
				}	
				}	
		}
		isSwipe = true;
	  }

	function goToPage( content ) { // В качестве аргумента принимаем id панели для перехода
		if(!isTransition){
			setIsTransition(true);
		}
		let {func, name, is_tab} = content;
		setSnackbar(null);
		setSnackbarNick(null);
		setSnackbarFlip(null);
		setSnackbarShowSym(null);
		if(func === '1'){
				setActivePanel(name)
			}
		else {
			// setTimeout(() => {
			if(!isIphone5 && currentTab !== 'convert'){
				bridge.send('VKWebAppSetSwipeSettings', {"history": true});
			}
			// }, 500)
			
			func(name);	
		}		
		if(is_tab){
			currentTab = name;
		}
		else{
			lastObject = {panel: name}
			history[currentTab].push(content);		
			window.history.pushState(lastObject, name );
		}
		if(isMobileWeb && name === 'show'){
			setTimeout(() => {
				window.scrollTo(0,0);
			}, 1)
		}
		 // Добавляем панель в историю
	  };

	function replaceData(content, indexHistory) {
		let {func, name} = content;
		if(func === '1'){
			setActivePanel(name)
		}
		else {
				func(name);
		}	
		history[currentTab] = history[currentTab].slice(0, indexHistory);
		history[currentTab][indexHistory] = content;
		window.history.replaceState(lastObject, name);
	}

	function setStateConvert(new_val) {
		stateConvert = new_val;
	}

	const convert_text_by_style = (style_function, text_for_convert) => {
		return ('encode' in style_function ? style_function.encode(text_for_convert) : typeof(style_function) == 'object' ? applyCharMap(style_function, text_for_convert) : style_function(text_for_convert))
	}
	
	function generateCardScroll_SelectStyle(){
		const generate_card_more = (key) => {
			return (<Card size="s" style={{ width: 216, height: 96, textAlign:"center"}} onClick={() => openMoreStyles(key)}>
			<div style={{ paddingTop:20}} className = 'MoreStylesDiv'>
				<Avatar size={38} style={{backgroundColor: "var(--text_subhead)", }}><Icon24MoreHorizontal fill="#ffffff" width={28} height={28} /></Avatar>
			</div>
			<Caption className='caption' style={{paddingTop:10}}>Больше стилей</Caption>
		</Card>)
		}
		let result = [];
		let CardScrollId = 1;
		for(let key in fonts){
			result.push(
				
				
				<Group key={makeid(7)}
							   separator="hide" 
							   header={<div 
								style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
									<Header className='no-select'>{fonts[key].name}</Header>
										{fonts[key].onlyEnglish ? 
										<Tooltip
									mode="accent"
									text="Только для латинских символов"
									isShown={stateToolTip.tooltip_select_style}
									onClose={() => closeTooltip('tooltip_select_style')}
									alignX="right"
									cornerOffset={60}
									offsetX={75}
									offsetY={10}>
										<img className='flag' src={BritishFlag}
									// 	onClick = {() => setToolTip({
									// 		tooltip: true
									// 	})
									//    }
										/>
										</Tooltip>
																    : null} 
		
						 		</div>}>
				<CardScroll /*id = 'TextCardScroll'*/ className = 'TextCardScroll' id = {CardScrollId++}>
					{fonts[key].content.slice(0, 3).map(style_function => <Card className='style_scroll_card' 
																				key={makeid(7)} 
																				size="s"
																				mode='shadow' 
																				style={{ width: 216, height: 96}}
																				onClick={() => selectStyle({
																					id: 0,
																					style_name: convert_text_by_style(style_function, fonts[key].textExample),
																					style_type: key,
																					style_function: style_function,
																					onlyEnglish: fonts[key].onlyEnglish,
																					RandomlyGenerate: fonts[key].RandomlyGenerate,
																					RandomStyles_default: false,
																					currentWindow: 'select_styles',
																				})}>
																					<div className =  {stateConvert.style_function === style_function ? 'cardDiv selected' :  "cardDiv"}>
																					<p id = {CardScrollId} className='example_text'>
																						 {convert_text_by_style(style_function, fonts[key].textExample)}
																					</p>
																					</div>
																					
																			</Card>)}
					{fonts[key].content.length > 3 ? generate_card_more(key) : undefined}
					{/*ЭТО НУЖНО <div className="arrow">р</div> */}
				</CardScroll>

			</Group>);
		}
		// console.log(result)
		return result
	}

	function generateMoreStyles(key) {
		let result = [];
		let fonts_style = fonts[key]
		if(fonts_style){
			result.push(<PanelHeader key={makeid(7)}
				left={<PanelHeaderButton key={makeid(7)} onClick={goHistoryBack}>
					{platform() === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
				</PanelHeaderButton>}
			>
				{fonts_style.name}
			</PanelHeader>)	
				if(fonts_style.onlyEnglish){
					result.push(<Div key={makeid(7)}><Caption className='caption no-select'>Данные стили адаптированы только под латинские символы!</Caption></Div>)
				}
				else if(fonts_style.RandomlyGenerate){
					result.push(<Div key={makeid(7)}><Caption className='caption no-select' key={makeid(7)}>Данные стили случайно создаются!</Caption></Div>)
				}
				result.push(<Div key={makeid(7)}>
								{fonts_style.content.map(style_function => <Card className='style_scroll_card' 
																				key={makeid(7)} 
																				size="l"  
																				mode='shadow' 
																				style={{ height: 48, marginBottom: 10 }}
																				onClick={() => selectStyle({
																					id: 0,
																					style_name: /*fonts_style.textExample  + ' ⇒ ' +*/ convert_text_by_style(style_function, fonts_style.textExample),
																					
																					style_type: key,
																					style_function: style_function,
																					onlyEnglish: fonts_style.onlyEnglish,
																					RandomlyGenerate: fonts_style.RandomlyGenerate,
																					RandomStyles_default: false,
																					currentWindow: 'select_more_styles',
																				})}>
										
																					<div className = "cardDiv">
																					<p className='example_text' size='2' weight = "medium">
																					{/*{fonts_style.textExample} ⇒*/}	 {convert_text_by_style(style_function, fonts_style.textExample)}
																					</p>
																					{stateConvert.style_function === style_function /*? <Icon24Done className='doneIcon' fill="var(--accent)" /> : <div style={{ width: 40, height: 59 }} />*/}																					</div>
																					{/* <Caption className='caption'>нажмите чтобы выбрать стиль</Caption> */}
																			</Card>)}
				</Div>)
		}
		return result;
	}

	function openMoreStyles(key) {
		setKeyMoreStyles(key);
		setIsTransition(true);
		goToPage({name: 'select_more_styles', func: setConverCurrentWindow});
	}

	function generateTools() {
		return tool_buttons.map(tool => <Card key={makeid(5)} size="l" mode = "shadow" style = {{ backgroundColor: 'var(--action_sheet_action_foreground)',	marginBottom:	3 }}
		onClick={() => goToPage({name:  tool.panel_id, func: openRootTools})} 
		>
		  <div className = "CardDiv" style={{ height: 96 }}>
		  <Title className = "ToolsCardTitle" level ="2" weight = "medium" style = {{ color: 'var(--counter_primary_text)'}}>{tool.text}</Title>
		  {tool.icon}
		  </div> 
	
		</Card>)
	}

	function generateSymCatalog() {
		let content = []
		for(let key in symbols){
			content.push(<Cell key={makeid(5)} description={"Символов: " + symbols[key].content.length}
								asideContent={<Icon24BrowserForward/>}
								onClick={() =>  openShowScreen(key)}>
				{symbols[key].name}
			</Cell>)
		}
		// setContentSymCatalog(content);
		return content;
	}

	function openShowScreen(key) {
		let content = []
		for(let sym of symbols[key].content){
			content.push(<div key={makeid(5)} className='sym_container' data-text={sym} onClick={event => CopyText(event, setSnackbarShowSym)}><span className='span_sym no-wrap'>{sym}</span></div>)
		}
		setStateSymShowScreen({
			name: symbols[key].name,
			content: content,
			style: symbols[key].style ? symbols[key].style : 'sym_div'
		})
		goToPage({name: 'show', func: setActivePanelSym});
	}

	function makeid(length) {
		var result           = '';
		var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for ( var i = 0; i < length; i++ ) {
		   result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	 }

	 function uniqueArray(arr) {
		let result = [];
		for (let str of arr) {
			if (!result.includes(str)) {
				result.push(str);
			}
		}

		return result;
	  }

	function GenerateNick() {
		nick_list = [];

		for ( let i = 0; i < 5; i++ ) {
			nick_list.push(GetNickname());
		}
		setNickCards(nick_list.map(nick => <Card key={makeid(5)} size="l" mode="shadow">
		<Div className='result_card' data-text={nick} onClick={event => CopyText(event, setSnackbarNick)} style={{textAlign: 'center'}}>
			{nick}
		</Div>
		</Card>))		
	}

	function TextFlip(e) {
		function reverseString(str) {
			return str.split("").reverse().join("");
		}

		function hasSymetricSym(str) {
			for (let char of str) {
				if(symetricSym.includes(char)){
					return true;
				}
			  }
			return false;
		}

		let text_for_flip = removeInvalidChars(e.target.value).substr(0, 200).toLowerCase();
		// let text_for_flip = e.target.value.substr(0, 200).toLowerCase();
		textForFlip = text_for_flip;
		let result = flipString(textForFlip);
		if(textForFlip === '' || textForFlip.trim() === ''){
			setConvertFlipResult(<Card key={makeid(5)} size="l" mode="shadow" >
			<Div className='result_card' style={{textAlign: 'center'}}>
				<Caption className='caption no_padding_top' >Введите текст, и он перевернется <span className='no-wrap'>(✯◡✯)</span></Caption>
			</Div>
			</Card>);
		}
		else if((reverseString(result) === textForFlip & !hasSymetricSym(textForFlip)) | isBadSymIn(textForFlip)){
			setConvertFlipResult(<Card key={makeid(5)} size="l" mode="shadow" >
						<Div className='result_card' style={{textAlign: 'center'}}>
							<Caption className='caption no_padding_top'>К сожалению, такие символы не поддерживаются.</Caption>
						</Div>
					</Card>);
		}
		else{
			setConvertFlipResult(
			<Card size = 'l' key = {makeid(6)} mode = 'shadow' data-text={result} onClick={event => CopyText(event, setSnackbarFlip)}>
				<Div style = {{textAlign: 'center'}}>
					{result}
				</Div>
			</Card>
		)
		}
	};

	function TranslitText(e) {
		let text_for_translit = removeInvalidChars(e.target.value).substr(0, 200);
		// let text_for_flip = e.target.value.substr(0, 200).toLowerCase();
		textForTranslit = text_for_translit;
		let result = translit(textForTranslit, 5);
		if(textForTranslit === '' || textForTranslit.trim() === ''){
			setConvertTranslitResult(<Card key={makeid(5)} size="l" mode="shadow" >
			<Div className='result_card' style={{textAlign: 'center'}}>
				<Caption className='caption'>Введите текст, и произойдет транслитерация <span className='no-wrap'>ʕ•ᴥ•ʔ</span></Caption>
			</Div>
			</Card>);
		}
		else if(isBadSymIn(textForTranslit) || textForTranslit === result){
			setConvertTranslitResult(<Card key={makeid(5)} size="l" mode="shadow" >
						<Div className='result_card' style={{textAlign: 'center'}}>
							<Caption className='caption no_padding_top'>К сожалению, такие символы не поддерживаются.</Caption>
						</Div>
					</Card>);
		}
		else{
			setConvertTranslitResult(
			<Card size = 'l' key = {makeid(6)} mode = 'shadow' data-text={result} onClick={event => CopyText(event, setSnackbarFlip)}>
				<Div style = {{textAlign: 'center'}}>
					{result}
				</Div>
			</Card>
		)
		}
	};

	async function convertText(e, new_style) {		
		let style_function;
		let result_text = [];
		let result_elements = [];
		// let text_for_convert = removeInvalidChars(e.target.value).substr(0, 100);
		let text_for_convert = e.target.value.substr(0, 100);




		if(new_style === undefined){
			new_style={};
		}
		if(new_style.style_function !== undefined){
			style_function = new_style.style_function;
		}
		else if(stateConvert.style_function !== undefined){
			style_function = stateConvert.style_function;
		}
		let isRandomStyles_default = ('RandomStyles_default' in new_style ? new_style.RandomStyles_default : null);
		if(isRandomStyles_default === null){
			isRandomStyles_default = stateConvert.RandomStyles_default;
		}
		let onlyEnglish = ('onlyEnglish' in new_style ? new_style.onlyEnglish : null);
		if(onlyEnglish === null){
			onlyEnglish = stateConvert.onlyEnglish;
		}
		let RandomlyGenerate = ('RandomlyGenerate' in new_style ? new_style.RandomlyGenerate : null);
		if(RandomlyGenerate === null){
			RandomlyGenerate = stateConvert.RandomlyGenerate;
		}
		if(e.target.isFirstChange === undefined){
			if(text_for_convert === textForConvert && !new_style.style_name){
				return;
			}
			textForConvert = text_for_convert;
		}
		if(isBadSymIn(text_for_convert)){
			result_elements = <Card key={makeid(5)} size="l" mode="shadow" >
				<Div className='result_card' style={{textAlign: 'center'}}>
					<Caption className='caption no_padding_top'>К сожалению, мы не поддерживаем эти символы.</Caption>
				</Div>
				</Card>
		}
		else if(text_for_convert.length > 0 & text_for_convert.trim() !== ''){
			if(isRandomStyles_default){
				result_text = generate(text_for_convert, stateConvert).split('\n\n');
			}
			else{
				if(RandomlyGenerate){
					for(let i = 0; i < 4; i++){
						result_text.push(convert_text_by_style(style_function, text_for_convert))
					}
				}
				else{
					result_text = convert_text_by_style(style_function, text_for_convert).split();
				}

			}
			// console.log(result_text)

			for(let row of uniqueArray(result_text)){
				if((row.length > 0 & row != text_for_convert) | result_text.length == 1){
					if(uniqueArray(result_text).indexOf(row) == 0 && stateToolTip.tooltip_convert){
						result_elements.push(
							<Card key={makeid(5)} size="l" mode="shadow">
						<Tooltip
							mode="accent"
							text="Нажми, чтобы скопировать текст!"
							isShown={stateToolTip.tooltip_convert}
							onClose={() => closeTooltip('tooltip_convert', e, new_style)}
							offsetX={10}
							offsetY={10}
						  >
						<Div className='result_card' data-text={row} onClick={event => CopyText(event, setSnackbar)} style={{textAlign: 'center'}}>
							{row}
						</Div>
						</Tooltip>
						</Card>)
					}
					else{
						result_elements.push(<Card key={makeid(5)} size="l" mode="shadow">
						<Div className='result_card' data-text={row} onClick={event => CopyText(event, setSnackbar)} style={{textAlign: 'center'}}>
							{row}
						</Div>
						</Card>);
					}

				}
			}
			if(onlyEnglish & result_text[0].toLowerCase() === text_for_convert.toLowerCase()){
				result_elements.unshift(
					<Card key={makeid(5)} size="l" mode="shadow">
				<Div className='result_card' style={{textAlign: 'center'}}>
					<Caption className='caption no_padding_top'>Этот шрифт не адаптирован для данных символов!</Caption>
				</Div>
				</Card>
				)
			}
			if(RandomlyGenerate){
				result_elements.unshift(
					<Card key={makeid(5)} size="l" mode="shadow" onClick={() => convertText({target: {
						value: text_for_convert,
						isFirstChange: true
					}}, {style_function: style_function,
						RandomStyles_default: false,
						RandomlyGenerate: true,
					})}>
					<Div className='result_card'>
						<Caption className='caption no_padding_top' style={{color: 'var(--text_link)'}}>Обновить</Caption>
					</Div>
					</Card>
				)
			}
			if(isRandomStyles_default){
				result_elements.unshift(
					<Card key={makeid(5)} size="l" mode="shadow" onClick={() => convertText({target: {
						value: text_for_convert,
						isFirstChange: true
					}}, {style_function: style_function,
						RandomStyles_default: true,
						RandomlyGenerate: false,
					})}>
					<Div className='result_card'>
						<Caption className='caption no_padding_top' style={{color: 'var(--text_link)'}}>Обновить</Caption>
					</Div>
					</Card>
				)
			}

		}
		else{

			result_elements = <Card key={makeid(5)} size="l" mode="shadow" >
				<Div className='result_card' style={{textAlign: 'center'}}>
					<Caption className='caption no_padding_top'>Введите текст, и случится магия <span className='no-wrap'>(っ◔◡◔)っ</span></Caption>
				</Div>
				</Card>
		}
		setConvertResult(result_elements);
	}

	function CopyText (e, setSnackbar) {
		countCopy += 1;
		if(countCopy === showAddCopy & !addIsLoadNow){
			addIsLoadNow = true;
			bridge.sendPromise("VKWebAppShowNativeAds", {ad_format:'reward'})
			.then(data => {console.log(data.result)
							addIsLoadNow = false;	})
		   .catch((error) => {console.log(error)
							  addIsLoadNow = false;	});
		   countCopy = 0;
		}
		setSnackbar(null);
		if(OS_name == ANDROID){
			bridge.send("VKWebAppCopyText", {"text": e.currentTarget.dataset.text})
			.then(res => setSnackbar(<Snackbar 
				layout="vertical"
				before={<Avatar size={24} style={{backgroundColor: "var(--accent)"}}><Icon16Done fill="#fff" width={14} height={14} /></Avatar>}
				onClose={() => setSnackbar(null)} >
					Скопировано
			</Snackbar>))
			.catch(res => setSnackbar(<Snackbar 
				layout="vertical"
				before={<Avatar size={24} style={{backgroundColor: "var(--field_error_border)"}}><Icon16Cancel fill="#ffffff" width={14} height={14} /></Avatar>}
				onClose={() => setSnackbar(null)} >
					Произошла ошибка при копировании
			</Snackbar>));
		}
		else{
			let result = copy(e.currentTarget.dataset.text);
			if (result){
				setSnackbar(<Snackbar 
								layout="vertical"
								before={<Avatar size={24} style={{backgroundColor: "var(--accent)"}}><Icon16Done fill="#fff" width={14} height={14} /></Avatar>}
								onClose={() => setSnackbar(null)} >
									Скопировано
							</Snackbar>);
			}
			else{
				setSnackbar(<Snackbar 
								layout="vertical"
								before={<Avatar size={24} style={{backgroundColor: "var(--field_error_border)"}}><Icon16Cancel fill="#ffffff" width={14} height={14} /></Avatar>}
								onClose={() => setSnackbar(null)} >
									Произошла ошибка при копировании
							</Snackbar>);
			}
			;
		}
	  	}
	
	async function goHistoryBack() {
		isSwipe = false;
		window.history.back()
		
	}	  	

	function selectStyle(style_data) {
		setStateConvert(style_data);
		styleName = style_data.style_name;
		convertText({target: {
			value: textForConvert,
		}}, style_data);
		if(style_data.currentWindow == 'select_more_styles'){
			replaceData({name: 'select_more_styles', func: setConverCurrentWindow}, 1);
		}
		setIsTransition(true);

		goHistoryBack()
	}

	function shareApp() {
		setSnackbar(null);
		setPopoutTools(<ScreenSpinner />)
		setTimeout(() => 	setPopoutTools(null), 3000
		)
		isOnline()
		.then(online => {
			if(online)	{
				bridge.send("VKWebAppShare", {"link": "https://vk.com/app7557452"})
				.then(() => setSnackbar(<Snackbar 
					layout="vertical"
					before={<Avatar size={24} style={{backgroundColor: "var(--dynamic_green)"}}><Icon16Done fill="#fff" width={14} height={14} /></Avatar>}
					onClose={() => setSnackbar(null)} >
						Успешно отправлено. Спасибо!
				</Snackbar>))
				.catch(() => setSnackbar(<Snackbar 
					layout="vertical"
					before={<Avatar size={24} style={{backgroundColor: "var(--field_error_border)"}}><Icon16Cancel fill="#ffffff" width={14} height={14} /></Avatar>}
					onClose={() => setSnackbar(null)} >
						Не удалось поделиться
				</Snackbar>))
			}	
			else	{
				setSnackbar(<Snackbar 
					layout="vertical"
					before={<Avatar size={24} style={{backgroundColor: "var(--field_error_border)"}}><Icon16Cancel fill="#ffffff" width={14} height={14} /></Avatar>}
					onClose={() => setSnackbar(null)} >
						Нет подключения к Интернету
				</Snackbar>)
			}
			setPopoutTools(null);
			});
	}
	 
	// открывает нужную вкладку в TabBar  
	function onStoryChange (e, main_data) {
		bridge.send('VKWebAppSetSwipeSettings', {"history": false});
		history[activePanel] = [history[activePanel][0]];
		let {name, func} = main_data;
		if(window.scrollY != 0){
			if(e.currentTarget.dataset.story == currentTab && main_data.window === name){
			scroll.scrollToTop();
		}
		else{
			console.log('story')
			window.scrollTo(0, 0);
		}
		}
		
		func(name);
		goToPage({name: e.currentTarget.dataset.story, func: '1', is_tab: true})
	  }

	function closeTooltip(tooltip, e, new_style){
		let new_obj = Object.assign(stateToolTip);
		new_obj[tooltip] = false;
		setStateToolTip(new_obj);
		if(e){
			convertText(e, new_style);
		}
		else{
			setFontCards(generateCardScroll_SelectStyle())
		}
	} 

	function openSelectPanel(panelName){
		setActivePanel(panelName);
	}

	function openRootTools(viewName) {
		setActivePanelTools(viewName)
		
	}

	function closeGuide(panel){
		if(panel === 1){
			document.getElementsByClassName('skip_guide')[0].style.color = 'rgb(183, 142, 250)';
			document.getElementsByClassName('mainDiv')[0].className = "element_for_hide";
		}
		else{
			document.getElementById('third').className = "element_for_hide_3";
		}
		scroll_utils.default.disable();
		setTimeout(()=>{setRootPanel('main');}, 180);
	}

	const [fontCards, setFontCards] = useState(generateCardScroll_SelectStyle());

	if(rootPanel === 'guide'){		
		bridge.send("VKWebAppSetViewSettings", {"status_bar_style": "light"})
		.catch(e => console.log(e.error_data));;
		return(
			<ConfigProvider scheme={props.scheme}>
			<View id='guide' activePanel={guideStep} 
					>
				<HelpGuide_1 id = '1'
					setGuideStep={setGuideStep}
					closeGuide={closeGuide}
				/>
				<HelpGuide_2 id = '2'
					setGuideStep={setGuideStep}
				/>
				<HelpGuide_3 id = '3'
					closeGuide={closeGuide}
				/>
			</View>
			</ConfigProvider>
		);
	}
	else if(isIphone5){
		return (<Epic id='main' activeStory={activePanel} tabbar={
			<Tabbar>
			  <TabbarItem
				onClick={e => onStoryChange(e, {name: 'convert', func: setConverCurrentWindow, window: converCurrentWindow})}
				selected={activePanel === 'convert'}
				data-story="convert"
				text="Конвертер"
			  ><Icon28SubtitlesOutline /></TabbarItem>
			  <TabbarItem
				onClick={e => onStoryChange(e, {name: 'sym_catalog', func: setActivePanelSym, window: activePanelSym})}					
				selected={activePanel === 'sym_catalog'}
				data-story="sym_catalog"
				text="Спецсимволы"
			  ><Icon28GhostOutline/></TabbarItem>
			  <TabbarItem
				onClick={e => onStoryChange(e, {name: 'tools', func: openRootTools, window: activePanelTools})}	
				selected={activePanel === 'tools'}
				data-story="tools"
				text="Инструменты"
			  ><Icon28ServicesOutline/></TabbarItem>
			</Tabbar>
		  }
		
		  >
			
		{/* Конвентер с диалоговым окном для выбора стиля */}
		<Root id="convert" activeView={converCurrentWindow}		   
				>

			<View id="convert" activePanel='panel' >
				<Home id='panel'
					  setActivePanel={openSelectPanel} 
					  setConverCurrentWindow={setConverCurrentWindow} 
					//   stateConvert={stateConvert} 
					  convertResult={convertResult} 
					  styleName={styleName}
					  convertText={convertText}
					  snackbar={snackbar} 
					  textForConvert={textForConvert}
					  goToPage={goToPage}
					//   currentPanelHistory={currentPanelHistory}
					  />
			</View>
			{/* Экран выбора стиля в конвенторе */}
			<View id="select_styles" activePanel="panel">
				<FontCatalog id="panel"
							 setStateConvert={selectStyle}
							 stateConvert={stateConvert}
							 content={fontCards}
							 setConverCurrentWindow={setConverCurrentWindow}
							 goHistoryBack={goHistoryBack}/>
			  </View>
			<View id="select_more_styles" activePanel="panel">
				<MoreStyles id="panel"
							 setConverCurrentWindow={setConverCurrentWindow}
							 content={generateMoreStyles(keyMoreStyles)}
							 />
			  </View>
			<View id="error_text" activePanel="panel" >
				<ErrorText id="panel"
								setConverCurrentWindow={setConverCurrentWindow}
								goHistoryBack={goHistoryBack}/>
			</View>
		</Root>
		{/* Экран инструментов */}
		<View id = 'tools' activePanel = {activePanelTools}
			popout={popoutTools}>
				<Tools id='tools'
					openRootTools = {openRootTools}
					openSelectPanel = {openSelectPanel}
					buttonsTools = {generateTools()}
					snackbar={snackbar}
					  shareApp={shareApp}
				/>
					<FlexFlip id = "FlexFlip"
						convertFlipResult = {convertFlipResult}
						TextFlip = {TextFlip}
						snackbarFlip={snackbarFlip}
						textForFlip={textForFlip}
						goHistoryBack={goHistoryBack}
					/>
					<GenerateRandomNick id = 'nick-generate'
						openRootTools = {openRootTools}
						snackbar={snackbarNick}
						GenerateNick = {GenerateNick}
						content = {nickCards}
						goHistoryBack={goHistoryBack}
					/>
					<TranslitPanel id = 'Translit'
						convertTranslitResult = {convertTranslitResult}
						TranslitText = {TranslitText}
						snackbarFlip={snackbarFlip}
						textForTranslit={textForTranslit}
						goHistoryBack={goHistoryBack}
					/>
		</View>
		<View id = 'sym_catalog' activePanel={activePanelSym}>
			<SymCatalog id='sym_catalog'
				content={generateSymCatalog()}>
				  
			</SymCatalog>
			<ShowScreenSym id='show' 
						   state={stateSymShowScreen}
						   snackbarShowSym={snackbarShowSym}
						   goHistoryBack={goHistoryBack}>

			</ShowScreenSym>
		</View>
		</Epic>)
	}
	else{
		return (
				<ConfigProvider scheme={props.scheme} transitionMotionEnabled={isTransition}>
			<Epic id='main' activeStory={activePanel} tabbar={
				<Tabbar>
				  <TabbarItem
					onClick={e => onStoryChange(e, {name: 'convert', func: setConverCurrentWindow, window: converCurrentWindow})}
					selected={activePanel === 'convert'}
					data-story="convert"
					text="Конвертер"
				  ><Icon28SubtitlesOutline /></TabbarItem>
				  <TabbarItem
					onClick={e => onStoryChange(e, {name: 'sym_catalog', func: setActivePanelSym, window: activePanelSym})}					
					selected={activePanel === 'sym_catalog'}
					data-story="sym_catalog"
					text="Спецсимволы"
				  ><Icon28GhostOutline/></TabbarItem>
				  <TabbarItem
					onClick={e => onStoryChange(e, {name: 'tools', func: openRootTools, window: activePanelTools})}	
					selected={activePanel === 'tools'}
					data-story="tools"
					text="Инструменты"
				  ><Icon28ServicesOutline/></TabbarItem>
				</Tabbar>
			  }
			
			  >
				
			{/* Конвентер с диалоговым окном для выбора стиля */}
			<Root id="convert" activeView={converCurrentWindow}
					   		
					>
						
				<View id="convert" activePanel='panel' >
					<Home id='panel'
						  setActivePanel={openSelectPanel} 
						  setConverCurrentWindow={setConverCurrentWindow} 
						//   stateConvert={stateConvert} 
						  convertResult={convertResult} 
						  styleName={styleName}
						  convertText={convertText}
						  snackbar={snackbar} 
						  textForConvert={textForConvert}
						  goToPage={goToPage}
						//   currentPanelHistory={currentPanelHistory}
						  />
				</View>
				{/* Экран выбора стиля в конвенторе */}
				<View id="select_styles" activePanel="panel">
					<FontCatalog id="panel"
								 setStateConvert={selectStyle}
								 stateConvert={stateConvert}
								 content={fontCards}
								 setConverCurrentWindow={setConverCurrentWindow}
								 goHistoryBack={goHistoryBack}/>
				  </View>
				<View id="select_more_styles" activePanel="panel">
					<MoreStyles id="panel"
								 setConverCurrentWindow={setConverCurrentWindow}
								 content={generateMoreStyles(keyMoreStyles)}
								 />
				  </View>
				<View id="error_text" activePanel="panel" >
					<ErrorText id="panel"
									setConverCurrentWindow={setConverCurrentWindow}
									goHistoryBack={goHistoryBack}/>
				</View>
			</Root>
			{/* Экран инструментов */}
			<View id = 'tools' activePanel = {activePanelTools}
				popout={popoutTools}>
					<Tools id='tools'
						openRootTools = {openRootTools}
						openSelectPanel = {openSelectPanel}
						buttonsTools = {generateTools()}
						snackbar={snackbar}
			  			shareApp={shareApp}
					/>
						<FlexFlip id = "FlexFlip"
							convertFlipResult = {convertFlipResult}
							TextFlip = {TextFlip}
							snackbarFlip={snackbarFlip}
							textForFlip={textForFlip}
							goHistoryBack={goHistoryBack}
						/>
						<GenerateRandomNick id = 'nick-generate'
							openRootTools = {openRootTools}
							snackbar={snackbarNick}
							GenerateNick = {GenerateNick}
							content = {nickCards}
							goHistoryBack={goHistoryBack}
						/>
						<TranslitPanel id = 'Translit'
							convertTranslitResult = {convertTranslitResult}
							TranslitText = {TranslitText}
							snackbarFlip={snackbarFlip}
							textForTranslit={textForTranslit}
							goHistoryBack={goHistoryBack}
						/>
			</View>
			<View id = 'sym_catalog' activePanel={activePanelSym}>
				<SymCatalog id='sym_catalog'
					content={generateSymCatalog()}>
			  		
				</SymCatalog>
				<ShowScreenSym id='show' 
							   state={stateSymShowScreen}
							   snackbarShowSym={snackbarShowSym}
							   goHistoryBack={goHistoryBack}>

				</ShowScreenSym>
			</View>
			</Epic>		
			</ConfigProvider>
		);
	}

}

export default App;

