import React from 'react';
import PropTypes from 'prop-types';
import {Div, FormLayout, Textarea, Caption, platform, IOS, PanelHeaderButton  } from '@vkontakte/vkui';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import CardGrid from '@vkontakte/vkui/dist/components/CardGrid/CardGrid';

import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';

const osName = platform();

const Translit = props => (
	<Panel id={props.id}>
       <PanelHeader left={<PanelHeaderButton onClick={() => props.goHistoryBack()}>
				                                    {osName === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
			                                            </PanelHeaderButton>}>Транслит</PanelHeader>
       <Div>
                    					{/* <Separator/> */}
                    <FormLayout>
					<Textarea  type="text" placeholder="Введите текст" top={<span >{props.textForTranslit.length}/200</span>}  onChange={props.TranslitText} value={props.textForTranslit}/>
                    </FormLayout>
					<CardGrid>
                        {props.convertTranslitResult} 
						{/* {props.convertResult}	 */}
					</CardGrid>
                    <Div style={{paddingTop: 15}}><Caption className='caption' >Транслитерация основана на ISO 9:1995 и ГОСТ 7.79-2000 системы А и Б <span className='no-wrap'>(возможна неточность транслита)</span></Caption></Div>

				
				
        {props.snackbarFlip}
		</Div>
    </Panel>
    );

Translit.propTypes = {
	id: PropTypes.string.isRequired,
};

export default Translit;