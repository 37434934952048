import React from 'react';
import PropTypes from 'prop-types';
import {Card, Div} from '@vkontakte/vkui';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import CardGrid from '@vkontakte/vkui/dist/components/CardGrid/CardGrid';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import Title from '@vkontakte/vkui/dist/components/Typography/Title/Title';
import Caption from '@vkontakte/vkui/dist/components/Typography/Caption/Caption';

import Icon24Done from '@vkontakte/icons/dist/24/done';
import Icon28CancelOutline from '@vkontakte/icons/dist/28/cancel_outline';

import './FontCatalog.css';

import RoundStar from '../img/round_star.png'
import Hammer from '../img/hammer.png';
import BritishFlag from '../img/britain_flag.png';


const FontCatalog = props => (
	<Panel id={props.id}>
        <PanelHeader left={<Icon28CancelOutline className = 'cancel-icon' onClick={() => props.goHistoryBack()}/>}>Выберите стиль</PanelHeader>
      <Group separator="hide"> {/*{header={<Header mode="secondary">Дефолтный стиль</Header>}style = {{ color: 'var(--counter_primary_text)'}}<Icon24Done className='doneIcon' fill="#ffffff" />*/ }
        {/* <Div style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
            <img className='flag' src={BritishFlag} style={{paddingInline: 5}}/><Caption className='caption_group'>- только для латинских символов</Caption>	
        </Div> 	
        <Div style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
            <img className='flag' src={Hammer} style={{paddingInline: 5}}/><Caption className='caption_group'>- случайно генерируется</Caption>	
        </Div> 			   														   					    */}
        <CardGrid>
        <Card size="l" mode="shadow" 
						onClick={() => props.setStateConvert({
                            id: 0,
                            style_name: 'Случайные стили',
                            style_type: undefined,
                            style_function: undefined,
                            RandomStyles_default: true,
                            RandomlyGenerate: false,
                            onlyEnglish: false,
                            currentWindow: 'convert'
                        })}
					>   
                        <div  >
                            <div className = "cardDiv" style={{margin:20}}>                        
                                <Title className = "cardTitle" level ="2" weight = "medium" >Случайные стили <span><img src={RoundStar} width={35} height={35} style={{paddingInline: 5}}/></span></Title>
                                {/* {props.stateConvert.style_name === 'Случайные стили' ? <Icon24Done className='doneIcon' fill="var(--accent)" /> : <div style={{ width: 40, height: 59 }} />} */}
                            </div>
                            {/* <Caption className='caption'>нажмите чтобы выбрать стиль</Caption> */}
                        </div> 
					</Card>
        </CardGrid>
      </Group>
      {props.content}
	</Panel>
);

FontCatalog.propTypes = {
	id: PropTypes.string.isRequired,
};

export default FontCatalog;