window.onload = () => {
    if(document.getElementById('dynamicName')){
		play();	
    }
};

function play() {
	setTimeout( function() {
		var logoTitle = 'TextTools!';
		var logoRandom = '';
        var possible = "-+*/|}{[]~\\\":;?/.><=+-_)(*&^%$#@!)}";
        let span_element = document.getElementById('dynamicName');

		function generateRandomTitle(i, logoRandom) {
			setTimeout( function() {
                // console.log(span_element.innerText )
				span_element.textContent = logoRandom;
			}, i*100 );
		}

		for( var i=0; i < logoTitle.length+1; i++ ) {
			logoRandom = logoTitle.substr(0, i);
			for( var j=i; j < logoTitle.length; j++ ) { 
				logoRandom += possible.charAt(Math.floor(Math.random() * possible.length)); 
			}
			generateRandomTitle(i, logoRandom);
			logoRandom = '';
		}

	}, 0 );
}