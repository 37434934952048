// function flip() {
//     result = flipString(document.form.original.value.toLowerCase());
//     document.form.flipped.value = result;
//  }
export function flipString(aString) {
    var last = aString.length - 1;
    var result = new Array(aString.length)
    for (var i = last; i >= 0; --i) {
        var c = aString.charAt(i)
        var r = flipTable[c]
        result[last - i] = r ? r : c
     }
 return result.join('')
 }
 var flipTable = {
     'a' : '\u0250',
     'b' : 'q',
     'c' : '\u0254',
     'd' : 'p',
     'e' : '\u01DD',
     'f' : '\u025F',
     'g' : '\u0183',
     'h' : '\u0265',
     'i' : '\u0131',
     'j' : '\u027E',
     'k' : '\u029E',
     //l : '\u0283',
     'm' : 'ɯ',
     'n' : 'u',
     'p' : 'd',
     'q' : 'ᕹ',
     'r' : '\u0279',
     't' : '\u0287',
     'u' : 'n',
     'v' : '\u028C',
     'w' : '\u028D',
     'y' : '\u028E',
     '.' : '\u02D9',
     '[' : ']',
     '(' : ')',
     ']' : '[',
     ')' : '(',
     '{' : '}',
     '}' : '{',
     '?' : '\u00BF',
     '!' : '\u00A1',
     "\'" : ',',
     ',' : "\'",
     '<' : '>',
     '>' : '<',
     '_' : '\u203E',
     '\u203F' : '\u2040',
     '\u2045' : '\u2046',
     '\u2234' : '\u2235',
     '\r' : '\n',
    '&': '⅋',

     'а' : 'ɐ',
     'б' : 'ƍ',
     'в' : 'ʚ',
     'г': 'ɹ',
     'д' : 'ɓ',
     'е' : 'ǝ',
     'ё' : 'ǝ',
     'ж' : 'ж',
     'з' : 'ε',
     'и' : 'n',
     'й' : 'ņ',
     'к' : 'ʞ',
     'л' : 'v',
     'м' : 'w',
     'н' : 'н',
     'о' : 'о',
     'п' : 'u',
     'р' : 'd',
     'с' : 'ɔ',
     'т' : 'ɯ',
     'у' : 'ʎ',
     'ф' : 'ȸ',
     'х' : 'х',
     'ц' : 'ǹ',
     'ч' : 'Һ',
     'ш' : 'm',
     'щ' : 'm',
     'ъ' : 'q',
     'ы' : 'ıq',
     'ь' : 'q',
     'э' : 'є',
     'ю' : 'oı',
     'я' : 'ʁ',
     '1':'\u0196',
 '2':'\u1105',
 '3':'\u0190',
 '4':'\u3123',
 '5':'\u03DB',
 '6':'9',
 '7':'\u3125',
 '8':'8',
 '9':'6',
 '0':'0'
 }
