import React, { useRef, useEffect, useState } from 'react';
import { CardGrid, Card, Select, Div, Button, Input, Textarea, Panel, PanelHeader, platform, IOS, ANDROID } from '@vkontakte/vkui';

import {brushes, backgrounds, sizes} from './DrawConfig'

import Icon28DeleteOutline from '@vkontakte/icons/dist/28/delete_outline';
import Icon28CopyOutline from '@vkontakte/icons/dist/28/copy_outline';
import Icon28ArrowUturnRightOutline from '@vkontakte/icons/dist/28/arrow_uturn_right_outline';

let context;
let canvas;

const osName = platform();
const IOSHeaderOffset = 52;
const ANDROIDHeaderOffset = 61;

let last_state = [];
let current_pix = [];

function generateBrushes() {
  let result = [];
  for(let brush of brushes){
    result.push(<option value={brush}>Кисть: {brush}</option>)
  }
  return result;
}

function generateBackgrounds() {
  let result = [];
  for(let background of backgrounds){
    result.push(<option value={background}>Фон: {background}</option>)
  }
  return result;
}

function generateSizes() {
  let result = [];
  for(let size of sizes){
    result.push(<option value={size}>Размер: {size}x{size}</option>)
  }
  return result;
}

const DrawComponent = props => {
  const selectBrush = useRef(null);
  const selectBackGround = useRef(null);

  const canvasRef = useRef(null)
  const contextRef = useRef(null)

  const [size, setSize] = useState(15);
  const [pixels, setPixels] = useState(generateStartPixels());
  const [background, setBackground] = useState(backgrounds[0]);
  const [brush, setBrush] = useState(brushes[0]);
  
  const [isDrawing, setIsDrawing] = useState(false)
  useEffect(() => {
    setPixels(generateStartPixels())
    canvas = canvasRef.current;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    canvas.style.height = `${window.innerHeight / 2}px`;
    canvas.style.width = `${window.innerWidth - 20}px`;


    context = canvas.getContext("2d");
    context.scale(1,1.9);
    clearCanvas();
    contextRef.current = context;
  }, [])

  function generateStartPixels() {
    let result = [];
    for(let i = 0; i < size; i++){
        result.push([]);
        for(let k = 0; k < size; k++){
            result[i].push('0')
        }
    }
    return result;
}

  const startDrawingMobile = (nativeEvent) => {
    console.log(current_pix)
    last_state.push([...pixels])
    last_state = last_state.slice(0, 5)
    let [clientX, clientY] = getTouchCoords(nativeEvent);
    clientX += window.pageXOffset;
    clientY += window.pageYOffset;
    contextRef.current.beginPath()
    contextRef.current.moveTo(clientX, clientY)
    let [IndexX, IndexY] = [~~(clientX / (canvas.width / size)), ~~(clientY / ((canvas.height /2 / size) + 1))];
    let new_pixels = pixels.slice(0);
    if(IndexX >= 0 & IndexY >= 0){
          new_pixels[IndexY > size - 1 ? size - 1: IndexY][IndexX > size - 1 ? size - 1: IndexX] = brush;
    }
    contextRef.current.arc(clientX, clientY, context.lineWidth / 4, 0, 2 * Math.PI )
    contextRef.current.stroke()
    setIsDrawing(true)
    current_pix = [...pixels.slice(0)]
  }

  const finishDrawing = () => {
   contextRef.current.closePath()

   clearCanvas()
   setIsDrawing(false)
  }

  const clearCanvas = () => {
        context.strokeStyle = "white"
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.strokeStyle = "gray"
        context.lineCap = "line"
        context.lineWidth = 2
        let span = canvas.width / size;
        let spanH = canvas.height / 2 / 14
        for(let i = 0; i < size; i++){
            for(let k = 0; k < size; k++){
                if(pixels[i][k] !== '0'){
                    context.fillRect(span * k, spanH * i, span - 1, spanH - 1);
                }
            }
        }    
        var w = canvas.width - 1;
        var h = canvas.height - 1;
        for (var x = -0.5; x < w; x += span){
            context.strokeRect(x, 0, 0.1, h);
        } 
        for (var y = -0.5; y < h; y += spanH ){
            context.strokeRect(0, y, w, 0.1);
        } 
        context.fillStyle = "#cff0ff";
            
        context.lineWidth = 10;
        context.lineCap = "round"
        context.strokeStyle = "#5BA7C8 ";
  }

  const draw = ({nativeEvent}) => {
    let [clientX, clientY] = getTouchCoords(nativeEvent);
    if(!isDrawing){
        return
    }
    clientX += window.pageXOffset;
    clientY += window.pageYOffset;
    let [IndexX, IndexY] = [~~(clientX / (canvas.width / size)), ~~(clientY / ((canvas.height /2 / size) + 1))];
    let new_pixels = pixels.slice(0);
    if( IndexX <= size & IndexY <= size & IndexX >= 0 & IndexY >= 0){
      new_pixels[IndexY > size - 1 ? size - 1: IndexY][IndexX > size - 1 ? size - 1: IndexX] = brush;
    }
    contextRef.current.lineTo(clientX, clientY + 5)
    contextRef.current.stroke()
    
  }

  const getTouchCoords = (nativeEvent) => {
    let clientX, clientY;
    clientX = nativeEvent.touches[0].clientX; 
    clientY = nativeEvent.touches[0].clientY;
    if(osName === IOS){
      return [clientX, clientY - IOSHeaderOffset]
    }
    else{
      return [clientX, clientY - ANDROIDHeaderOffset]
    }
  }

  const generateShowPixels = () => {
      let result = [];
      for (let index = 0; index < pixels.length; index++) {
        result.push(<div>{pixels[index].join('').split('0').join(background)}</div>);
          
      }
      return result;
  }

  const generateCopySym = () => {
    let result = '';
      for (let index = 0; index < pixels.length; index++) {
        result += pixels[index].join('').split('0').join(background);
          
      }
      return result;
  }

  const undoAction = () => {
    console.log(last_state[0]);
    let new_state = last_state.shift().slice(0);
    setPixels([...new_state]);
    pixels = [...new_state];
    clearCanvas()
    console.log([...new_state])
  }

  const clearPicture = () =>{
    setPixels(generateStartPixels());
    pixels = generateStartPixels();
    clearCanvas()
  }
  
  const checkPixelsNew = () => {
    let current_pix = '';
    let new_pix = '';
    pixels.forEach(row => current_pix += row.join());
    generateStartPixels().forEach(row => new_pix += row.join());
    return current_pix === new_pix;
  }

  return (
    <Panel id={props.id}>
      <PanelHeader>
        Рисунок
      </PanelHeader>
      <div >
        <canvas style={{ margin: 10, border: '1px solid', touchAction: 'none'}}
      // onMouseDown={startDrawing}
      // onMouseUp={finishDrawing}
      // onMouseMove={draw}
      onTouchMove={draw}
      onTouchEnd={finishDrawing}
      onTouchStart={startDrawingMobile}
    //   onClick={onClickCanvas}
      ref={canvasRef}
    />
    <div style={{display: 'flex', justifyContent: 'center', marginBottom:20}}>
          <Select style={{marginRight: 20}} onChange={e => (setBrush(e.currentTarget.value))}>
            {generateBrushes()}
          </Select >
          <Select onChange={e => (setBackground(e.currentTarget.value))}> 
            {generateBackgrounds()}
          </Select>
          
        </div>

      <div style={{display: 'flex', justifyContent: 'center', marginBottom:20}}>
          <Button disabled={checkPixelsNew()} mode='destructive' style={{marginRight: 40}} onClick={clearPicture}>
            {<Icon28DeleteOutline/>}
          </Button >
          <Button disabled={checkPixelsNew()} style={{marginRight: 40}} onClick={undoAction}>
            {<Icon28ArrowUturnRightOutline style={{transform: 'rotate(180deg) scale(1, -1)'}}/>}
          </Button >
          <Button mode='commerce'>
            {<Icon28DeleteOutline/>}
          </Button >
        </div>
      {/* <div>
        <Select onChange={e => (setBackground(e.currentTarget.value))}> 
            {generateSizes()}
          </Select>
      </div> */}
    <CardGrid style={{marginBottom: 30}}>
      <Card mode='shadow' size='l' style={{textAlign: "center", marginBottom: 30}}>
        <div style={{marginTop: 5, marginBottom: 5}}>
          {generateShowPixels()}
        </div>
      </Card>
    </CardGrid>
    {/* <Textarea>
      
    </Textarea> */}
    {/* <div style={{textAlign: 'center'}}>    </div> */}

    </div>
    </Panel>
  );
}

export default DrawComponent;