export let symbols = {
    emoji:{
        name: 'Смайлики',
        style: 'sym_div_big',
        content: [
            '( ͡° ͜ʖ ͡°)', '( ͡ᵔ ͜ʖ ͡ᵔ )','ʕ•ᴥ•ʔ','(ᵔᴥᵔ)','(ಥ﹏ಥ)','(ง°ل͜°)ง', '(ಠ_ಠ)',	'(ಥ_ಥ)',	'◘_◘',	'ب_ب',	'ಠoಠ',	'(⊙ヮ⊙)',	'(✿｡✿)',	'⊙﹏⊙',	'◉◡◉',	'◉_◉',
            '( ･_･)♡',	'(◕‿◕)',	'(◑‿◐)','╘[◉﹃◉]╕', '┌( ಠ_ಠ)┘',	'⊙︿⊙',	'ಠ▃ಠ', '(o˘◡˘o)', '(★ω★)', 'ッ', 'ツ', 'ヅ', 'ツ', 'ゾ', 'シ', 'ジ','㋛'
        ]
    },
    squares: {
        name: 'Прямоугольники',
        content: [
        '▄','▀','◘','◫','◪','◩','▣','▤','▥','▦','▧','▨','▩','▪','▫','▬','▭', '▮','▯','▰','▱','▓','▒','░','▐','▏','▎','▍','▌','▋','▊','▉','█','▂','▃','▄','▅','▆','▇','█','▒','▓','█','🔲','🔳'
                ]},
    circles:  {
        name: 'Круги',
        content: [
            '○','◌','◍','◎','●','◐','◑','◯','◗','◖','◕','◔','◓','◒'
        ]},
    frames: {
        name: 'Рамки',
        content: [
            '╴','╵','╶','╷','╸','╹','╺','╻','╼','╽','╾','╿','┇','┆','┅','┄','┃', '│','╇','╆','╅','╄','╃','╂','╁','╀','┿','┾','┽','┼','┻','┺','┹','┸','┷','┶','┵','┴','┳','┲','┱','┰','┯','┮','┭','┬','┫','┪','┩','┨','┧','┦','┥','┤','┣','┢','┡','┠','┟','┞','┝','├','┛','┚','┙','┘','┗','┖','┕','└','┓','┒','┑', '┐','┏','┎','┍','┌','┋','┊','┉','┈','┇','┆','╬','╫','╪','╩','╨','╧','╦','╥','╤','╣','╢','╡╠','╟','╞','╝','╜╛','╚','╙'
        ]},
    arrow: {
        name: 'Стрелки',
        content: [
            '↔','↕','←','↖','↑','↗','→','↘','↓','↙','˿','≪','«','»','↨','⇦','⇧','⇨','⇩', '⇐', '⇑','⇒', '⇓','⇔','⇕','⇖','⇗','⇘','⇙','⇚','⇛','⇜','⇝','⇄','⇅','⇆','⇇','⇈','⇉','⇊','↺','↻','↰','↱','↲','↳','↴','↵','↶','↷','￩','￪','￫','￬','➜','➝','➞','➟','➡','➥','➦','➨','➩','➪','➯','➱','➲'
        ],
    },   
    triangle: {
        name: 'Треугольники',
        content: [
            '▲','△','▴','▵','▶','▷','▸','▹','►','▻','▼','▽','▾','▿','◀','◁','◂','◃','◄','◅','◮','◭','◬'
        ]},
    chess: {
        name: 'Шахматы',
        content: [
            '♔','♕','♖','♗','♘','♙','♙','♚','♛','♜','♝','♞','♟'
        ]},
    stars: {
        name: 'Звезды',
        content: ['☽','★','☆','☾','✩','✪','✫','✬','✭','✮','✯','✰','✵','✶','✷','✸','✹','✺','✻','✼','✽','✾','✿','❀','❁','❂','❃']
    },
    maths:{
        name: 'Математические',
        content: ['½','⅓','⅔','⅛','⅜','⅝','⅞','Ա','ϖ','Ø','ק','√','∛','∜','%','‰','‱','∫','∂','∑','∏','−','±','×','•','÷','≈','∞','∈','∩','∪','⊂','⊃','⊆','⊇']
    },
    economic:{
        name: 'Экономические',
        content: [
            '€','£','Ұ元','₣','₱','฿','¤₡','₮','₭','₩','₴','$','₰¢','₤円','₢','₥','₫','₦','zł','﷼₠','₧','₯','₨','Kč','¥','₳','₲','₪','₵'
        ]
    },
    // china:{
    //     name: 'Иероглифы',
    //     content: [
    //         '牡','マ','キ','グ','ナ', '桳', '搮', '獩','汰','祡','癯','敩','汃','灩','洈','楡','獸','整','瑸','匆','牴','湩','捳','楲','瑰', 'ル','フ','ァ','系','路','弅','圳','潷','䉤','歯','敓','晳','煈', '匀', '散','敮','ㄠ','洘','楡','獸','⽳','牰','癩','瑡','硥克','瑞','大','阪','市','立','学','鎰','命','科','ャマ能','力','ϒ','人','は','妻','ス','テ','ィ','要','望','通','り','玉','宏','¥','サ','丹','谷','Ѫ','灯','影','伝','鶐','ラ','リ坆汰','楯','慭','湩','砺','獳','砯','獳','洔','楡杮','硜','獳','癟','潫','瑮','歡','整','活','楡','獸','獡','搄','瑡','͡牵獸','⽳','慭','湩','昺','慲','敭','慭','湩','砃','獳','昍','慬愾','敬','瑲','搨','捯','浵','湥','潣','歯','敩','⼼','捳','楲','瑰','猃','牴','昆慲敭','硥','汰','桜','捡','楫'
    //     ]
    // }
};
