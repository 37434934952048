import React from 'react';
import PropTypes from 'prop-types';
import { platform, IOS } from '@vkontakte/vkui';
import { Input, SelectMimicry, FormLayout, Card, Separator} from '@vkontakte/vkui';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import PanelHeaderButton from '@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import Header from '@vkontakte/vkui/dist/components/Header/Header';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Caption from '@vkontakte/vkui/dist/components/Typography/Caption/Caption';
import CardGrid from '@vkontakte/vkui/dist/components/CardGrid/CardGrid';
import Group from '@vkontakte/vkui/dist/components/Group/Group';
import Headline from '@vkontakte/vkui/dist/components/Typography/Headline/Headline';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Icon28CancelOutline from '@vkontakte/icons/dist/28/cancel_outline';


import './GenerateRandomNick.css';

const osName = platform();

const GenerateRandomNick = props => (
	<Panel id={props.id}>
        <PanelHeader className = 'Header' left={<PanelHeaderButton onClick={() => props.goHistoryBack()}>
				                                    {osName === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
			                                            </PanelHeaderButton>}>
                Генератор
            </PanelHeader>
        <Div>
                    					{/* <Separator/> */}
					<CardGrid>
                        <Button size = 'xl' style={{marginTop: 10}} onClick = { () => console.log(props.GenerateNick())}>Сгенерировать ник</Button>
                        <div style={{marginTop: 10}}>
                            {props.content} 
                        </div>
						{/* {props.convertResult}	 */}
					</CardGrid>
                    <Div style={{paddingTop: 15}}><Caption className='caption' >Вы можете придать нику уникальный стиль в нашем конвертере текста!</Caption></Div>

				
				

		</Div>
        {props.snackbar}
    </Panel>

);

GenerateRandomNick.propTypes = {
    id: PropTypes.string.isRequired,
};

export default GenerateRandomNick;